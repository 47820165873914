import React, { useEffect, useState } from 'react'
import { BsCreditCard } from 'react-icons/bs'
import { FaFileContract } from 'react-icons/fa'
import { Button, Col, Form, message, Row, Typography } from 'antd'
import Payment from './payment/Payment'
import Contract from './contractCreate/Contract'
import { AiOutlineClose, AiOutlineUser } from 'react-icons/ai'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import {
  clearAllReducer,
  clearClientData,
  clearPayments,
  clientRestore,
  contractPage,
  newClientPhones,
  productAdd,
  setClientData,
} from '../../redux/actions'
import PersonCont from './person/PersonCont'
import { FiShoppingBag } from 'react-icons/all'
import ProductCont from './product/ProductCont'
import AutoComplete from './autoCompleteProduct/AutoCompleteProduct'
import { UZS } from '../../utils/constants/currencies'

function ContractCont(props) {
  const [form] = Form.useForm()
  const {
    totalPrice,
    firstPayment,
    openPage,
    contractPage,
    setClientData,
    clientData,
    clientPhones,
    clearClientData,
    delay,
    clearPayments,
    clearAllReducer,
    tableCount,
    productList,
    productAdd,
    newClientPhones,
  } = props
  const [currency, setCurrency] = useState(UZS)

  const updateClientFunc = (val) => {
    if (openPage !== 3) return
    let phonesArr = []
    let updateObj = {}
    let i = 0
    Object.keys(val).forEach((field) => {
      if (field.includes('phones')) {
        phonesArr.push({
          phone: val[field],
          active: clientPhones[i].active,
        })
        i++
      } else {
        updateObj = {
          ...updateObj,
          [field]: val[field],
        }
      }
    })
    updateObj = {
      ...updateObj,
      clientPhones: phonesArr,
    }
    setClientData(updateObj)
    newClientPhones(phonesArr)
    contractPage(4)
  }

  const goToPaymentSections = () => {
    if (openPage === 1) {
      if (productList.length > 0) {
        contractPage(2)
      } else {
        message.error("Mahsulot qo'shilmagan!")
      }
    } else {
      contractPage(2)
    }
  }

  const getProductInfo = () => {
    let count = 0
    let sum = 0
    productList.forEach((product) => {
      count += product.count * 1
      sum += product.productDto.price * 1 * (product.count * 1)
    })
    return {
      sum,
      count,
    }
  }

  const goToClientPage = () => {
    if (tableCount === 0 && totalPrice) contractPage(3)
    else message.error("Mahsulot qo'shilmagan yoki jadval xato to'ldirilgan!")
  }

  useEffect(() => {
    return () => {
      clearAllReducer()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Form form={form} name="basic" onFinish={updateClientFunc}>
        <div
          style={{
            position: 'fixed',
            zIndex: '1000',
            backgroundColor: '#FAFBFD',
            width: '88vw',
          }}
        >
          <div className="headerClass">
            <div
              className={openPage >= 1 ? 'Contractactive' : 'headerClass1'}
              onClick={() => {
                contractPage(1)
              }}
            >
              <FiShoppingBag className="iconClassClient" />
            </div>
            <span className="spanClass">Mahsulot</span>
            <div
              className={openPage >= 2 ? 'Contractactive' : 'headerClass1'}
              onClick={goToPaymentSections}
            >
              <BsCreditCard className="iconClassClient" />
            </div>
            <span className="spanClass">To'lov</span>
            <div
              className={openPage >= 3 ? 'Contractactive' : 'headerClass1'}
              onClick={goToClientPage}
            >
              <AiOutlineUser className="iconClassClient" />
            </div>
            <span className="spanClass">Mijoz</span>
            <button
              type={'submit'}
              className={openPage >= 4 ? 'Contractactive' : 'headerClass1'}
            >
              <FaFileContract className="iconClassClient" />
            </button>
            <span className="spanClass">Shartnoma</span>
          </div>
        </div>
        <Row
          className="CardRowClass"
          style={{
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            marginTop: '105px',
          }}
        >
          {productList.length > 0 ? (
            <Col span={8}>
              <div>
                <div
                  style={{
                    height: '84px',
                    width: '277px',
                    background: '#ffe500',
                    boxShadow: '0px 4px 15px rgba(255, 229, 0, 0.5)',
                    position: 'relative',
                    borderRadius: '7px',
                    marginLeft: '5px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      padding: '5px',
                      paddingTop: '10px',
                      paddingLeft: '10px',
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'IBM Plex Sans',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '18px',
                        lineHeight: '100%',
                        color: '#434A59',
                        marginRight: '10px',
                        marginTop: '5px',
                      }}
                    >
                      Umumiy mahsulotlar:
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      fontFamily: 'IBM Plex Sans',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '18px',
                      marginTop: '3px',
                      lineHeight: '100%',
                      color: '#434A59',
                      paddingLeft: '10px',
                    }}
                  >
                    {getProductInfo().count} ta
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: 'IBM Plex Sans',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '18px',
                      marginTop: '3px',
                      lineHeight: '100%',
                      color: '#434A59',
                      paddingLeft: '10px',
                    }}
                  >
                    {getProductInfo().sum.toLocaleString('ru')} {currency}
                  </Typography>
                  <AiOutlineClose
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      width: '18px',
                      height: '18px',
                      borderRadius: '50%',
                      background: 'rgba(255, 255, 255, 0.7)',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      productAdd([], 1, true)
                      clearClientData()
                      clearPayments()
                      contractPage(1)
                    }}
                  />
                </div>
              </div>
            </Col>
          ) : null}
          {totalPrice !== 0 ? (
            <Col span={8}>
              <div
                style={{
                  height: '84px',
                  width: '277px',
                  background: '#FFE500',
                  boxShadow: '0px 4px 15px rgba(255, 229, 0, 0.5)',
                  position: 'relative',
                  borderRadius: '7px',
                  marginLeft: '5px',
                  padding: '5px',
                }}
              >
                <Typography
                  style={{
                    fontFamily: 'IBM Plex Sans',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '18px',
                    lineHeight: '100%',
                    color: '#434A59',
                    marginRight: '10px',
                    padding: '5px',
                    paddingTop: '10px',
                  }}
                >
                  <NumberFormat
                    value={totalPrice}
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={''}
                  />{' '}
                  {currency}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'IBM Plex Sans',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '100%',
                    color: '#434A59',
                    marginRight: '10px',
                    padding: '5px',
                    paddingTop: '4px',
                  }}
                >
                  <NumberFormat
                    value={firstPayment}
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={''}
                  />{' '}
                  {currency}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'IBM Plex Sans',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '100%',
                    color: '#434A59',
                    textAlign: 'right',
                    marginRight: '10px',
                    padding: '5px',
                    paddingTop: '0',
                  }}
                >
                  {delay + ' oy'}
                </Typography>
                <AiOutlineClose
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    width: '18px',
                    height: '18px',
                    borderRadius: '50%',
                    background: 'rgba(255, 255, 255, 0.7)',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    clearPayments()
                    contractPage(1)
                  }}
                />
              </div>
            </Col>
          ) : null}
          {clientData && (
            <Col span={8}>
              <div>
                <div
                  style={{
                    height: '84px',
                    width: '277px',
                    background: '#ffe500',
                    boxShadow: '0px 4px 15px rgba(255, 229, 0, 0.5)',
                    position: 'relative',
                    borderRadius: '7px',
                    marginLeft: '5px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      padding: '5px',
                      paddingTop: '10px',
                      paddingLeft: '10px',
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'IBM Plex Sans',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '16px',
                        maxWidth: '250px',
                        lineHeight: '100%',
                        color: '#434A59',
                        marginRight: '10px',
                      }}
                    >
                      {clientData.fullName}
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      fontFamily: 'IBM Plex Sans',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '100%',
                      color: 'rgba(67, 74, 89, 0.6)',
                      position: 'absolute',
                      bottom: '10px',
                      right: '15px',
                    }}
                  >
                    {clientData.clientPhones.map((phn) => {
                      if (phn.active) {
                        return <>{phn.phone}</>
                      } else {
                        return null
                      }
                    })}
                  </Typography>
                  <AiOutlineClose
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      width: '18px',
                      height: '18px',
                      borderRadius: '50%',
                      background: 'rgba(255, 255, 255, 0.7)',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      clearClientData()
                      contractPage(2)
                    }}
                  />
                </div>
              </div>
            </Col>
          )}
        </Row>
        <div
          className="DeleteCardButton"
          onClick={() => {
            clearAllReducer()
            contractPage(1)
          }}
        >
          <span className="DeleteCardButtonIcon">X</span>
        </div>
        {openPage === 1 && (
          <ProductCont currency={currency} setCurrency={setCurrency} />
        )}
        {openPage === 2 && <Payment getProductInfo={getProductInfo} />}
        {openPage === 3 && <PersonCont form={form} />}
        {openPage === 4 && <Contract currency={currency} />}
      </Form>
      {openPage === 1 && (
        <div
          style={{
            marginLeft: '1.6rem',
            marginTop: '-0.6rem',
            marginRight: '1.5rem',
          }}
          className="autoComplete"
        >
          <AutoComplete currency={currency} />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '2.2rem 0 0 0',
        }}
      >
        <Button
          onClick={() => {
            if (openPage === 4) {
              contractPage(3)
            }
            if (openPage === 3) {
              contractPage(2)
            }
            if (openPage === 2) {
              contractPage(1)
            }
          }}
          disabled={openPage === 1}
          style={{
            margin: '5px',
          }}
        >
          Orqaga
        </Button>
        <Button
          onClick={() => {
            if (openPage === 1) {
              goToPaymentSections()
            }
            if (openPage === 2) {
              goToClientPage()
            }
            if (openPage === 3) {
              form
                .validateFields()
                .then((values) => {
                  updateClientFunc(values)
                })
                .catch((errorInfo) => {
                  message.warning("Mijoz ma'lumotlari xato!")
                })
            }
          }}
          disabled={openPage === 4}
          style={{
            margin: '5px',
            backgroundColor: '#7944e3',
            color: 'white',
          }}
        >
          Oldinga
        </Button>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    openPage: state.contractCont.openPage,
    clientData: state.clientCont.clientData,
    clientType: state.clientCont.clientType,
    clientPhones: state.clientCont.clientPhones,
    paymentType: state.paymentCont.paymentType,
    delay: state.paymentCont.delay,
    tableCount: state.paymentCont.tableCount,
    savedClient: state.contractCont.savedClient,
    productList: state.productCont.productList,
    firstPayment: state.paymentCont.firstPayment,
    totalPrice: state.paymentCont.totalPrice,
  }
}

const mapDispatchToProps = {
  contractPage,
  setClientData,
  newClientPhones,
  clearClientData,
  clearPayments,
  clearAllReducer,
  clientRestore,
  productAdd,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractCont)
