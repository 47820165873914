import { AutoComplete, Form, Input, Spin } from 'antd'
import React, { useState } from 'react'
import { FiSave } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { useProductSearch } from '../../../queries/queries'
import { productAdd } from '../../../redux/actions'

import { LoadingOutlined } from '@ant-design/icons'
import { IoPricetag } from 'react-icons/io5'
import { customCurrency } from '../../../utils/helper/customCurrency'
import { parseLocaledString } from '../../../utils/helper/parseLocaledString'
import './AutoCompleteProduct.scss'

const AutoCompleteProduct = ({ currency }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [productName, setProductName] = useState('')
  const [price, setPrice] = useState('')
  const [count, setCount] = useState(1)
  const productSearch = useProductSearch(productName)
  const timerRef = React.useRef(null)

  //onFinish
  const onFinish = (fields) => {
    const product = {
      isSave: true,
      count: fields.count,
      productDto: {
        name: fields.name,
        price: parseLocaledString(fields.price),
      },
    }
    dispatch(productAdd(product, 'new'))
    form.resetFields()
    setCount(1)
    setPrice('')
  }

  //option item
  const optionItem = (item) => ({
    price: item.price,
    id: item.id,
    name: item.name,
    value: item.id,
    label: (
      <div style={{ display: 'flex' }}>
        {item.name}
        <span style={{ display: 'block', marginLeft: 'auto' }}>
          <IoPricetag />
          &nbsp;
          {item.price.toLocaleString()}
        </span>
      </div>
    ),
  })

  //options
  const option = productSearch.data?.map((item) => optionItem(item))

  //handle selected product
  const handleSelect = (_, label) => {
    let productCount = form.getFieldValue('count')
    form.setFieldsValue({
      name: label.name,
      price: customCurrency(String(label.price)),
      count: !productCount ? 1 : productCount,
    })
    setCount(!productCount ? 1 : productCount)
    setPrice(customCurrency(String(label.price)))
  }

  return (
    <Form form={form} className="autoCompleteForm" onFinish={onFinish}>
      <Form.Item
        name="name"
        className="name"
        rules={[
          {
            required: true,
            message: 'Mahsulot nomini kiriting!',
          },
        ]}
      >
        <AutoComplete
          options={option}
          // dataSource={option}
          dropdownMatchSelectWidth={500}
          onSelect={handleSelect}
          // backfill={true}
        >
          <Input
            placeholder="Mahsulot nomi"
            onChange={(e) => {
              clearTimeout(timerRef.current)
              timerRef.current = setTimeout(() => {
                setProductName(
                  e.target.value.length > 2 ? e.target.value : null
                )
              }, 1000)
            }}
            suffix={
              productSearch.isLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 12,
                        marginLeft: '10px',
                        color: '#5f5e5e',
                      }}
                      spin
                    />
                  }
                />
              ) : (
                <span />
              )
            }
          />
        </AutoComplete>
      </Form.Item>
      <Form.Item
        name="count"
        className="count"
        rules={[
          {
            required: true,
            message: 'Mahsulot sonini kiriting!',
          },
        ]}
      >
        <Input
          placeholder="Soni"
          type="number"
          onChange={(e) => setCount(e.target.value)}
          min={1}
        />
      </Form.Item>
      <Form.Item
        name="price"
        className="price"
        rules={[
          {
            required: true,
            message: 'Mahsulot narxini kiriting!',
          },
        ]}
      >
        {/* <InputNumber
          placeholder="Narxi"
          // type="number"
          min={1}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          }
          parser={(value) => value.replace(/\$\s?|( *)/g, '')}
          onChange={(e) => {
            setPrice(e)
          }}
        /> */}
        <Input
          placeholder="Narxi"
          value={price}
          onChange={(e) => {
            const newValue = customCurrency(e.target.value)
            setPrice(newValue)
            form.setFieldsValue({
              price: newValue,
            })
          }}
        />
      </Form.Item>
      <Form.Item className="totalPrice">
        <p id="totalPrice">
          {(parseLocaledString(price) * count).toLocaleString()} {currency}
        </p>
      </Form.Item>
      <Form.Item className="saveBtn">
        <button type="submit">
          <FiSave />
        </button>
      </Form.Item>
    </Form>
  )
}

export default AutoCompleteProduct
