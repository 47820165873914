import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Layout, Pagination, Popconfirm, Space } from 'antd'
import React, { useState } from 'react'
import { FaSms } from 'react-icons/fa'
import ExcelIcon from '../../icons/ExcelIcon'
import {
  useArrearageMessageMutation,
  useDownloadExcelArrearageMutation,
} from '../../queries/mutations'
import { useArrearage } from '../../queries/queries'
import { ASC, sortBy } from '../../utils/constants/sortType'
import Table from './Table'

const { Content } = Layout

const initialFilter = {
  type: null,
  name: null,
}

const Arrearage = () => {
  const [searchString, setSearchString] = useState('?page=1&size=10')
  // const [filter, setFilter] = useState (false);
  const [sort, setSort] = useState({ type: ASC, by: sortBy.DATE })
  const [filterObj, setFilterObj] = useState(initialFilter)

  const { data, isLoading, isFetching } = useArrearage(searchString, sort)
  const excelMutation = useDownloadExcelArrearageMutation()
  const sendMessageMutation = useArrearageMessageMutation()

  const sendRequest = (page) => {
    let sendApi = `?page=${page}&size=10`
    Object.keys(filterObj).forEach((item) => {
      if (filterObj[item]) {
        sendApi = sendApi + `&${item}=${filterObj[item]}`
      }
    })
    setSearchString(sendApi)
  }

  const downloadExcelFunc = () => {
    let sendApi = ''
    Object.keys(filterObj).forEach((item) => {
      if (filterObj[item]) {
        if (sendApi) sendApi += `&${item}=${filterObj[item]}`
        else sendApi = `?${item}=${filterObj[item]}`
      }
    })
    excelMutation.mutate(sendApi)
  }

  const sendMessageAllClients = () => {
    sendMessageMutation.mutate()
  }

  return (
    <div>
      <Content
        style={{
          padding: '10px 20px 0 20px',
          overflow: 'initial',
          backgroundColor: '#FAFBFD',
        }}
      >
        <Space
          direction="horizontal"
          style={{
            width: '100%',
            margin: '20px 20px',
            marginRight: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Input
              className="circle-input"
              style={{ width: 400, height: '38px' }}
              placeholder="Tezkor qidiruv..."
              onChange={(e) => {
                setFilterObj({
                  ...filterObj,
                  name: e.target.value,
                })
              }}
              onPressEnter={() => sendRequest(1)}
              prefix={<SearchOutlined className="site-form-item-icon" />}
            />
            {/* <button
              type="button"
              // onClick={() => {
              //   setFilter(!filter)
              //   setFilterObj({
              //     type: null,
              //     clientName: null,
              //     houseId: null,
              //   })
              //   sendRequest(1, true)
              // }}
              className="ClientFiltrButton"
              style={{
                marginLeft: '5px',
                transform: 'translateY(5px)',
              }}
            >
              <VscListFilter
                style={{
                  position: 'absolute',
                  fontSize: '1.4rem',
                  left: '20px',
                  fontFamily: 'IBM Plex Sans',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '18px',
                  letterSpacing: '0px',
                }}
              />
              <span
                style={{
                  margin: '0 0 0 25px',
                  fontFamily: 'IBM Plex Sans',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '18px',
                  letterSpacing: '0px',
                }}
              >
                Filtr
              </span>
            </button> */}
          </div>
          <div className="df">
            <Popconfirm
              title="Xabar yuborishni tasdiqlang"
              onConfirm={sendMessageAllClients}
              okText="Yuborish"
              cancelText="Ortga"
            >
              <Button
                loading={sendMessageMutation.isLoading}
                style={{
                  marginRight: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                icon={
                  <FaSms
                    style={{
                      paddingRight: '3px',
                      fontSize: '18px',
                    }}
                  />
                }
              >
                Xabar yuborish
              </Button>
            </Popconfirm>
            <Button
              style={{
                marginRight: '20px',
              }}
              className="arrearage_excel_btn"
              loading={excelMutation.isLoading}
              icon={<ExcelIcon />}
              onClick={downloadExcelFunc}
            >
              Chop etish
            </Button>
          </div>
        </Space>
      </Content>
      {/*{filter && (*/}
      {/*  <div>*/}
      {/*    <Row align="top" gutter={[32, 32]} style={{width: '1100px'}}>*/}
      {/*      <Col span={6}>*/}
      {/*        <h1 className="filterFieldTitle">Tartiblash:</h1>*/}
      {/*        <Select*/}
      {/*          allowClear*/}
      {/*          style={{width: '100%'}}*/}
      {/*          placeholder="Tartiblash.."*/}
      {/*          onChange={(e) => {*/}
      {/*            setFilterObj({*/}
      {/*              ...filterObj,*/}
      {/*              type: e*/}
      {/*            })*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <Option value={"maxAmount"}>*/}
      {/*            Maksimal qarzdorlik*/}
      {/*          </Option>*/}
      {/*          <Option value={"minAmount"}>*/}
      {/*            Minimal qarzdorlik*/}
      {/*          </Option>*/}
      {/*          <Option value={"maxDay"}>*/}
      {/*            Maksimal kechikish*/}
      {/*          </Option>*/}
      {/*          <Option value={"minDay"}>*/}
      {/*            Minimal kechikish*/}
      {/*          </Option>*/}
      {/*        </Select>*/}
      {/*      </Col>*/}
      {/*      <Col lg={3}>*/}
      {/*        <Button*/}
      {/*          type={"primary"}*/}
      {/*          style={{*/}
      {/*            marginTop: '27px'*/}
      {/*          }}*/}
      {/*          onClick={() => {*/}
      {/*            sendRequest(1)*/}
      {/*          }}>*/}
      {/*          Qidirish*/}
      {/*        </Button>*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  </div>*/}
      {/*)}*/}
      <Table
        isFetching={isFetching}
        data={data?.arrearages}
        spinner={isLoading}
        sort={sort}
        setSort={setSort}
      />
      <Pagination
        current={data?.currentPage}
        pageSize={'10'}
        showSizeChanger={false}
        total={data?.totalElements}
        onChange={(e) => {
          sendRequest(e)
        }}
      />
    </div>
  )
}

export default Arrearage
