import { UserOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Form, Input, message, Row, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import CalendarIcon from '../../../icons/CalendarIcon'
import { useCreateSpecialContract } from '../../../queries/mutations'
import {
  addClientPhone,
  checkClientPhone,
  clearAllReducer,
  delateClientPhone,
} from '../../../redux/actions'
import { contractTypes } from '../../../utils/constants/contractTypes'
import { UZS } from '../../../utils/constants/currencies'
import { isEmptyArr } from '../../../utils/helper/isEmptyArr'
import { validateDateString } from '../../../utils/helper/validateDateString'
import AutoCompleteProduct from '../autoCompleteProduct/AutoCompleteProduct'
import SponsorCont from '../person/SponsorCont'
import SpecialProduct from './SpecialProduct'

const { Option } = Select
const ContractPlusSpecial = () => {
  // const [save, setSave] = useState(0)
  const [formInstance] = Form.useForm()
  const products = useSelector((state) => state.productCont.productList)
  const clientPhones = useSelector((state) => state.clientCont.clientPhones)
  const dispatch = useDispatch()
  const createSpecialContract = useCreateSpecialContract()
  const history = useHistory()
  const sponsorList = useSelector((state) => state.sponsorCont.sponsorList)
  const [enableSponsor, setEnableSponsor] = useState(false)
  const [currency, setCurrency] = useState(UZS)

  const onFinish = async (values) => {
    let newSponsorList = []

    //amount
    let amount = 0
    await products.forEach((product) => {
      amount += product.count * product.productDto.price
    })

    //phones
    let phonesArr = []
    let i = 0
    await Object.keys(values).forEach((field) => {
      if (field.includes('phones')) {
        phonesArr.push({
          phone: values[field],
          active: clientPhones[i].active,
        })
        i++
      }
    })

    newSponsorList = sponsorList.map((sponsor) => {
      let newSponsor = sponsor
      delete newSponsor.dateBirth
      return newSponsor
    })

    //data
    const data = {
      amount,
      endDate: values.endDate,
      contractAmountType: currency,
      createdDate: `${values.startDate} ${moment().format('HH:mm:ss')}`,
      clientDto: {
        fullName: values.fullName,
        clientPhones: phonesArr,
        address: values.address,
      },
      contractProductDtoList: products,
      sponsorDtoList: isEmptyArr(newSponsorList) ? null : newSponsorList,
    }

    if (products.length > 0) {
      createSpecialContract.mutate(data)
    } else {
      message.error('Mahsulot kiritilmagan!')
    }
  }

  //clear states
  useEffect(() => {
    return () => {
      dispatch(clearAllReducer())
    }
    // eslint-disable-next-line
  }, [])

  // enable add sponsor
  const enableAddSponsor = () => {
    setEnableSponsor(true)
  }

  // handle currency
  const handleCurrency = (currency) => {
    setCurrency(currency)
  }

  return (
    <div className={'productCont'}>
      <Form
        id="form"
        className="specialContract"
        onFinish={onFinish}
        form={formInstance}
      >
        <label>
          <span className="title">Valyuta turi:</span>
          <Form.Item
            name="contractAmountType"
            rules={[
              {
                required: true,
                message: 'Shartnoma valyuta turi tanlanmagan!',
              },
            ]}
            initialValue={currency}
          >
            <Select onChange={handleCurrency} value={currency}>
              <Option value="UZS">UZS</Option>
              <Option value="USD">USD</Option>
            </Select>
          </Form.Item>
        </label>
        <label>
          <span className="title">F.I.O:</span>
          <Form.Item
            name="fullName"
            rules={[
              {
                required: true,
                message: 'Ism kiritilmagan!',
              },
            ]}
          >
            <Input suffix={<UserOutlined />} />
          </Form.Item>
        </label>
        <label>
          <span className="title">Manzili:</span>
          <Form.Item
            name="address"
            rules={[
              {
                required: true,
                message: 'Manzil kiritilmagan!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </label>
        {clientPhones.map((phone, index) => (
          <div key={index} style={{ display: 'flex' }}>
            {index === 0 ? (
              <label htmlFor="Phone" className="title">
                <span className="title">Tel. raqami:</span>
              </label>
            ) : (
              <label
                htmlFor="Phone"
                className=""
                style={{ color: 'transparent' }}
              >
                <span className="title"></span>
              </label>
            )}
            <Form.Item
              style={{ minWidth: '16rem' }}
              name={'phones' + index + ''}
              rules={[
                {
                  required: true,
                  message: 'Telefon kiritilmagan!',
                  len: 13,
                },
              ]}
            >
              <InputMask
                style={{
                  border: 'none',
                  borderBottom: '1px solid  #989FB9',
                  width: '100%',
                  // marginLeft: '17px',
                }}
                mask="+\9\98999999999"
                maskChar=""
                id="Phone"
              >
                {(inputProps) => (
                  <Input className="clientPhone" {...inputProps} />
                )}
              </InputMask>
            </Form.Item>
            <Checkbox
              checked={phone.active}
              onChange={() => {
                dispatch(checkClientPhone(index))
              }}
              style={{
                marginLeft: index === 0 ? '15px' : '18px',
                display: 'inline-block',
              }}
            />
            {index === 0 ? (
              <>
                {clientPhones.length !== 1 && (
                  <span
                    className="YuridikRemoveButton"
                    onClick={() => {
                      dispatch(delateClientPhone(index))
                    }}
                  >
                    <span
                      style={{
                        position: 'absolute',
                        display: 'block',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-55%)',
                      }}
                    >
                      -
                    </span>
                  </span>
                )}
                <span
                  className="YuridikAddButton"
                  onClick={() => {
                    dispatch(addClientPhone())
                  }}
                >
                  <span
                    style={{
                      position: 'absolute',
                      display: 'inline-block',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%,-55%)',
                    }}
                  >
                    +
                  </span>
                </span>
              </>
            ) : (
              <span
                className="YuridikRemoveButton"
                onClick={() => {
                  dispatch(delateClientPhone(index))
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    display: 'inline-block',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-55%)',
                  }}
                >
                  -
                </span>
              </span>
            )}
          </div>
        ))}
        <label>
          <span className="title">Boshlanish vaqti:</span>
          <Form.Item
            style={{ minWidth: '20rem' }}
            name="startDate"
            rules={[
              {
                required: true,
                message: 'Sana kiritilmagan!',
              },
              {
                validator: (_, value) => {
                  if (value) {
                    formInstance.setFieldsValue({
                      startDate: validateDateString(value),
                    })
                  }
                  return Promise.resolve()
                },
              },
            ]}
            initialValue={moment().format('DD.MM.YYYY')}
          >
            <InputMask mask="99.99.9999" maskChar={''}>
              {(inputProps) => (
                <Input
                  suffix={<CalendarIcon />}
                  placeholder={'kk.oo.yyyy'}
                  {...inputProps}
                />
              )}
            </InputMask>
          </Form.Item>
        </label>
        <label>
          <span className="title">Tugash muddati:</span>
          <Form.Item
            style={{ minWidth: '20rem' }}
            name="endDate"
            rules={[
              {
                required: true,
                message: 'Sana kiritilmagan!',
              },
              {
                validator: (_, value) => {
                  if (value) {
                    formInstance.setFieldsValue({
                      endDate: validateDateString(value),
                    })
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <InputMask mask="99.99.9999" maskChar={''}>
              {(inputProps) => (
                <Input
                  suffix={<CalendarIcon />}
                  placeholder={'kk.oo.yyyy'}
                  {...inputProps}
                />
              )}
            </InputMask>
          </Form.Item>
        </label>
        {enableSponsor && <SponsorCont type={contractTypes.SPECIFIC} />}
        {!enableSponsor && (
          <div className="contractPlusInputsCont">
            <label
              className="YuridikShLabel cur-p txt-btn"
              onClick={enableAddSponsor}
            >
              Kafil qo'shish
            </label>
          </div>
        )}
        <Row className={'header'}>
          <Col className="title" span={7}>
            Nomi
          </Col>
          <Col className="title" span={4}>
            Miqdori
          </Col>
          <Col className="title" span={5}>
            Narxi
          </Col>
          <Col className="title" span={5}>
            Umumiy summa
          </Col>
          <Col className="title" span={3} />
        </Row>
        {/* <SpecialProduct data={null} index={'new'} save={save} /> */}
      </Form>
      {products?.map((data, index) => (
        <SpecialProduct
          data={data}
          key={index}
          index={index}
          currency={currency}
        />
      ))}
      <div className="autoComplete specific">
        <AutoCompleteProduct currency={currency} />
      </div>
      {/* <div className="autoComplete forSimple"> */}
      {/* <AutoCompleteForSimple /> */}
      {/* <p>for simple contract</p> */}
      {/* </div> */}
      {/* <div onClick={() => setSave(save + 1)} className={'buttonAdd'}>
        <FiPlus />
        <span>Yangi mahsulot qo’shish</span>
      </div> */}
      <div className="btnCont">
        <Button htmlType="reset" onClick={() => history.push('/main/contract')}>
          Bekor qilish
        </Button>
        <Button
          form="form"
          type="primary"
          htmlType="submit"
          loading={createSpecialContract.isLoading}
        >
          Tasdiqlash
        </Button>
      </div>
    </div>
  )
}

export default ContractPlusSpecial
