import React from 'react'
import {Table, Tag} from "antd";

const {Column} = Table;

function CountGraphTable({dataForTable}){
  return (
    <div className="ContractInfoHistoryOperationsCont">
      <Table
        showSizeChanger={true}
        size={"small"}
        dataSource={dataForTable}
        pagination={false}
        rowClassName="ContractInfoHistoryTableRow"
        style={{marginTop: '5px'}}
      >
        <Column title={'Sana'} dataIndex="createdDate" key="createdDate"/>
        <Column
          title={'Summa'}
          dataIndex="amount"
          key="amount"
          className="ContractInfoHistoryTableCountColumn"
          render={e => (e.toLocaleString ('ru'))}/>
        <Column
          dataIndex="type"
          key="type"
          title={"To'lov turi"}
          render={type => (
            <Tag
              style={{
                width: '50px',
                textAlign: 'center'
              }}
              color={
                (type === "TRANSFER" && "#7c30d9") || (type === "CARD" && "#2ecb38") || (type === "CASH" && "#ff7825") || (type === "BANK" && "#ff5cd5")
              }
              key={type}>
              {
                (type === "CARD" && "KARTA") || (type === "CASH" && "NAQD") || (type === "BANK" && "BANK") || (type === "TRANSFER" && "P2P") || type
              }
            </Tag>
          )}/>
        <Column title={"Mas'ul"} key="employeeFullName" dataIndex={'employeeFullName'}/>
      </Table>
    </div>
  )
}

export default CountGraphTable
