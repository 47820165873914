import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { Space, Button } from 'antd'
import {
  CreditCardOutlined,
  FileProtectOutlined,
  PoweroffOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import SMButton from './SidebarMenuButton'
import AuthService from '../services/AuthService'
import { GiMoneyStack, RiMessage2Line } from 'react-icons/all'
import logo from '../images/Logo.png'
import { useCheckNewVersion } from '../queries/queries'
import { useSelector } from 'react-redux'

function Sidebar(props) {
  const [sign, signOut] = useState(false)
  const user = AuthService.getCurrentUser()
  const userRole = AuthService.getRole()
  const url = props.url
  const location = useLocation()
  const { data: newProjectVersion } = useCheckNewVersion()
  const projectVersion = useSelector((state) => state.projectVersion.version)

  useEffect(() => {
    if (projectVersion && newProjectVersion) {
      if (newProjectVersion !== projectVersion) {
        window.location.reload()
      }
    }
  }, [location, projectVersion, newProjectVersion])

  if (sign) return <Redirect push to="/" />

  return (
    <div className="sidebar">
      <div className="sidebarTop">
        <Space align="center">
          <img className="sidebarTopLogo" src={logo} alt={'Logo'} />
          <div className="sidebarTopTitle">
            "Muto" <br /> boshqaruv paneli
          </div>
        </Space>
      </div>
      <SMButton
        url={`${url}/contract`}
        plusUrl={`${url}/contractPlus`}
        plusUrlSpecial={`${url}/contractPlusSpecial`}
        name={'Shartnoma'}
        icon={<FileProtectOutlined />}
      />
      <SMButton
        url={`${url}/payment`}
        plusUrl={`${url}/paymentPlus`}
        name={"To'lov"}
        icon={<CreditCardOutlined />}
      />
      {/* <SMButton
        url={`${url}/client`}
        name={'Mijozlar'}
        icon={<CreditCardOutlined />}
      /> */}
      <SMButton
        url={`${url}/arrearage`}
        name={'Qarzdorlik'}
        icon={<GiMoneyStack />}
      />
      <SMButton
        url={`${url}/message`}
        name={'Xabarlar'}
        icon={<RiMessage2Line />}
      />
      <SMButton
        url={`${url}/settings`}
        name={'Sozlash'}
        icon={<SettingOutlined />}
      />
      <div className="sidebarBottom">
        <Space align="base-line">
          <div
            style={{
              display: 'inline-block',
              width: '30px',
              height: '30px',
              fontSize: '18px',
              borderRadius: '50%',
              backgroundColor: '#ccc',
            }}
          >
            <UserOutlined style={{ color: '#fff' }} />
          </div>
          <div className="sidebarBottomUserData" style={{ textAlign: 'left' }}>
            <div className="sidebarBottomUserName">{user?.username}</div>
            <div className="sidebarBottomUserRole">{userRole}</div>
          </div>
          <Button
            className="logoutButton"
            onClick={() => {
              AuthService.logout()
              signOut(true)
            }}
          >
            <PoweroffOutlined />
          </Button>
        </Space>
        <div className="sidebarBottomAppVersion">MUTO app v 1.0</div>
      </div>
    </div>
  )
}

export default Sidebar
