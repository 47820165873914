import { Modal } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AutoCompleteProduct from '../ContractPlus/autoCompleteProduct/AutoCompleteProduct'
import SpecialProduct from '../ContractPlus/SpecialContract/SpecialProduct'

import '../ContractPlus/product/product.scss'
import { productAdd } from '../../redux/actions'
import { useCreateContractParts } from '../../queries/mutations'
import { useParams } from 'react-router-dom'
import { modalTypes } from '../../utils/constants/modalTypes'

const PartsOfContractModal = ({ visible, setVisible, id, setId, currency }) => {
  const params = useParams()
  const dispatch = useDispatch()
  const products = useSelector((state) => state.productCont.productList)
  const createContractParts = useCreateContractParts(setVisible)

  // on cancel
  const onCancel = () => {
    setVisible(false)
  }

  // after close
  const afterClose = () => {
    dispatch(productAdd([], 1, true))
    setId(null)
  }

  // title
  const title = () => {
    if (id) {
      return 'Partiyalarni tahrirlash'
    } else return 'Partiya yaratish'
  }

  // type
  const type = () => {
    if (id) {
      return modalTypes.UPDATE
    } else return modalTypes.CREATE
  }

  // on ok
  const onOk = () => {
    let productsData = []

    products?.forEach(
      (product) =>
        (productsData = [
          ...productsData,
          {
            count: product?.count,
            productDto: product.productDto,
          },
        ])
    )

    createContractParts.mutate({
      data: {
        id,
        products: productsData,
      },
      contractId: +params?.id,
      type: type(),
      firstTime: false,
    })
  }

  return (
    <Modal
      visible={visible}
      centered
      onCancel={onCancel}
      afterClose={afterClose}
      okText="Saqlash"
      cancelText="Bekor qilish"
      okButtonProps={{ loading: createContractParts.isLoading }}
      title={title()}
      onOk={onOk}
      width={850}
      className="partsModal"
    >
      <div className="productCont">
        {products?.map((data, index) => (
          <SpecialProduct
            data={data}
            key={index}
            index={index}
            currency={currency}
          />
        ))}
        <AutoCompleteProduct currency={currency} />
      </div>
    </Modal>
  )
}

export default PartsOfContractModal
