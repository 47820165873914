import $api, { API_URL } from '../http/$api'

class AuthService {
  isSigned() {
    try {
      if (JSON.parse(localStorage.getItem('user'))) {
        return true
      } else {
        localStorage.clear()
        return false
      }
    } catch (error) {
      localStorage.clear()
      return false
    }
  }

  // return Redirect Component
  logout() {
    localStorage.clear()
    $api.get(`${API_URL}/auth/logout`)
  }

  // return String URL
  getLandingPage(role) {
    switch (role) {
      case 'DEVELOPER':
        return '/developer/company'
      default:
        return '/main/contract'
    }
  }

  // return String[] UserRoles
  getRoleCode() {
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user')).roles[0]
    } else {
      return null
    }
  }

  // return String UserRole
  getRole() {
    if (this.getRoleCode())
      switch (this.getRoleCode()) {
        case 'SUPER_ADMIN':
          return 'Superadmin'
        case 'ADMIN':
          return 'Administator'
        case 'USER':
          return 'Xodim'
        case 'MANAGER':
          return 'Manager'
        case 'DEVELOPER':
          return 'Dasturchi'
        default:
          return null
      }
    else return null
  }

  getCurrentUser() {
    try {
      return JSON.parse(localStorage.getItem('user'))
    } catch (error) {
      localStorage.clear()
      window.location.replace('/')
      return null
    }
  }

  // TODO
  hasAccess(page) {
    let role = this.getRoleCode() // []

    switch (role) {
      case 'USER': {
        if (page.indexOf('/contract') > -1) {
          return true
        } else {
          switch (page) {
            case '/':
              return true
            case '/payment':
              return true
            case '/paymentPlus':
              return true
            case '/arrearage':
              return true
            case '/message':
              return true
            default:
              return false
          }
        }
      }
      case 'SUPER_ADMIN': {
        if (page.indexOf('/contract') > -1) {
          return true
        } else {
          switch (page) {
            case '/':
              return true
            case '/payment':
              return true
            case '/paymentPlus':
              return true
            case '/settings':
              return true
            case '/arrearage':
              return true
            case '/message':
              return true
            case '/client':
              return true
            case '/client/:id':
              return true
            default:
              return false
          }
        }
      }
      case 'MANAGER': {
        if (page.indexOf('/contract') > -1) {
          return true
        } else {
          switch (page) {
            case '/':
              return true
            case '/arrearage':
              return true
            case '/message':
              return true
            case '/client':
              return true
            case '/client/:id':
              return true
            default:
              return false
          }
        }
      }
      case 'ADMIN': {
        if (page.indexOf('/contract') > -1) {
          return true
        } else {
          switch (page) {
            case '/':
              return true
            case '/payment':
              return true
            case '/paymentPlus':
              return true
            case '/settings':
              return true
            case '/arrearage':
              return true
            case '/message':
              return true
            default:
              return false
          }
        }
      }
      case 'DEVELOPER': {
        switch (page) {
          case '/':
            return true
          case '/companyEmployees':
            return true
          case '/company':
            return true
          default:
            return false
        }
      }
      default:
        return false
    }
  }
}

export default new AuthService()
