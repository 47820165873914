import React, { useState } from 'react'
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Table,
  Typography,
} from 'antd'
import Column from 'antd/es/table/Column'
import { LoadingOutlined } from '@ant-design/icons'
import { useSendMessageOneClient } from '../../queries/mutations'
import { ASC, DESC, sortBy } from '../../utils/constants/sortType'
import ArrowTop from '../../icons/ArrowTop'
import ArrowBottom from '../../icons/ArrowBottom'

const { TextArea } = Input
const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 12, marginLeft: '10px', color: '#5f5e5e' }}
    spin
  />
)

const TableArrearage = ({ spinner, data, isFetching, sort, setSort }) => {
  const [selectedItem, setSelectedItem] = useState({
    clickRow: false,
    item: null,
  })

  const [messageBody, setMessageBody] = useState('')
  const sendOneMessage = useSendMessageOneClient()

  const sentMessageFunc = () => {
    if (messageBody) {
      sendOneMessage.mutate({
        id: selectedItem?.item?.clientId,
        text: messageBody,
      })
      setMessageBody('')
    } else {
      message.error('Xabar matni kiritilmagan!')
    }
  }

  // change sort
  const changeSort = (sortBy) => {
    let type

    if (sort?.type === ASC) {
      type = DESC
    } else {
      type = ASC
    }

    setSort({
      type,
      by: sortBy,
    })
  }

  return (
    <>
      <Table
        showSizeChanger={false}
        loading={spinner}
        size={'middle'}
        dataSource={data}
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedItem({
                clickRow: true,
                item: record,
              })
            },
          }
        }}
        rowKey={(record) => record.contractId}
        pagination={false}
        style={{ width: '100%' }}
      >
        <Column
          title="Shartnoma"
          key="contractNumber"
          dataIndex={'contractNumber'}
        />
        <Column
          title={() => (
            <span>
              Mijoz
              {isFetching && <Spin indicator={antIcon} />}
            </span>
          )}
          key="clientFullName"
          dataIndex={'clientFullName'}
        />
        <Column
          title="Telefon raqam"
          dataIndex="clientPhone"
          key="clientPhone"
          render={(v) => {
            return <>{v}</>
          }}
        />
        <Column
          title={
            <div
              className="df ai-c cur-p nonSelectable"
              onClick={() => changeSort(sortBy.DATE)}
            >
              Sana &nbsp;
              <div
                className={`${
                  sort.by === sortBy.DATE ? 'active' : ''
                } sort_cont`}
              >
                <ArrowTop className={sort.type === ASC ? 'active' : ''} />
                <ArrowBottom className={sort.type === DESC ? 'active' : ''} />
              </div>
            </div>
          }
          dataIndex="date"
          key="date"
        />
        <Column
          title={
            <div
              className="df ai-c cur-p nonSelectable"
              onClick={() => changeSort(sortBy.DELAY)}
            >
              Kechikish &nbsp;
              <div
                className={`${
                  sort.by === sortBy.DELAY ? 'active' : ''
                } sort_cont`}
              >
                <ArrowTop className={sort.type === ASC ? 'active' : ''} />
                <ArrowBottom className={sort.type === DESC ? 'active' : ''} />
              </div>
            </div>
          }
          dataIndex="delay"
          key="delay"
        />
        <Column
          title={
            <div
              className="df ai-c cur-p nonSelectable"
              onClick={() => changeSort(sortBy.ARREARAGE)}
            >
              Qarzdorlik &nbsp;
              <div
                className={`${
                  sort.by === sortBy.ARREARAGE ? 'active' : ''
                } sort_cont`}
              >
                <ArrowTop className={sort.type === ASC ? 'active' : ''} />
                <ArrowBottom className={sort.type === DESC ? 'active' : ''} />
              </div>
            </div>
          }
          // dataIndex="arrearage"
          key="arrearage"
          render={(record) =>
            `${record.arrearage?.toLocaleString('ru')} ${
              record.contractAmountType
            }`
          }
        />
      </Table>
      <Modal
        visible={selectedItem.clickRow}
        title="Ma'lumot"
        onCancel={() => {
          setSelectedItem({
            ...selectedItem,
            clickRow: false,
          })
          setMessageBody('')
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setSelectedItem({
                ...selectedItem,
                clickRow: false,
              })
              setMessageBody('')
            }}
          >
            Ortga qaytish
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              setSelectedItem({
                ...selectedItem,
                clickRow: false,
              })
              sentMessageFunc()
            }}
          >
            Xabar yuborish
          </Button>,
        ]}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Typography className="clientInfoInsideModal">
              Mijoz:
              <span style={{ color: '#939397', paddingLeft: '6px' }}>
                {selectedItem?.item?.clientFullName}
              </span>
            </Typography>
            <Typography className="clientInfoInsideModal">
              Shartnoma:
              <span style={{ color: '#939397', paddingLeft: '6px' }}>
                {selectedItem?.item?.contractNumber}
              </span>
            </Typography>
            <Typography className="clientInfoInsideModal">
              Tel:
              <span style={{ color: '#939397', paddingLeft: '4px' }}>
                <div
                  style={{
                    paddingLeft: '40px',
                    transform: 'translateY(-16px)',
                  }}
                >
                  {selectedItem?.item?.clientPhone}
                </div>
              </span>
            </Typography>
          </Col>
          <Col span={12}>
            <Typography className="messageBody">Xabar matni</Typography>
            <TextArea
              rows={4}
              value={messageBody}
              style={{ resize: 'none', marginTop: '10px' }}
              onChange={(e) => {
                setMessageBody(e.target.value)
              }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default TableArrearage
