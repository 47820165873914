import { Button, DatePicker, Input, Modal, Typography } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { BsCheckCircle } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  useCreateContractMutation,
  useDownloadContract,
} from '../../../queries/mutations'
import { clearAllReducer, contractDate } from '../../../redux/actions'
import { isEmptyArr } from '../../../utils/helper/isEmptyArr'

function disabledDate(current) {
  return current && current > moment().endOf()
}

function Contract({ currency }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [contract, setContract] = useState(null)
  const [contractNumer, setContractNumer] = useState('')
  const [isNumberVisible, setIsNumberVisible] = useState(false)
  const history = useHistory()

  const dispatch = useDispatch()

  const contractCreate = useCreateContractMutation(setContract, setModalOpen)
  const downloadContract = useDownloadContract()

  const clientData = useSelector((state) => state.clientCont.clientData)
  const contractDateVal = useSelector((state) => state.contractCont.date)
  const paymentData = useSelector((state) => state.paymentCont)
  const productList = useSelector((state) => state.productCont.productList)
  const getPdfContract = () => {
    history.push(`/main/contract/${contract.id}`)
    downloadContract.mutate(contract.id)
  }
  const sponsorList = useSelector((state) => state.sponsorCont.sponsorList)

  const goToHome = () => {
    dispatch(clearAllReducer())
  }

  const createContractFunc = async () => {
    const newProductList = []
    let monthlyPayments = []
    let newSponsorList = []

    if (paymentData?.residue !== 0) {
      paymentData?.table.forEach((payment) => {
        monthlyPayments.push({
          amount: payment?.oneMonthPay,
          date: payment?.date,
        })
      })
    }

    productList.forEach((product) => {
      newProductList.push({
        count: product.count,
        productDto: product.productDto,
      })
    })

    newSponsorList = sponsorList.map((sponsor) => {
      let newSponsor = sponsor
      delete newSponsor.dateBirth
      return newSponsor
    })

    const sendObj = {
      amount: paymentData?.totalPrice,
      delay: paymentData?.delay,
      firstPayment: paymentData?.firstPayment,
      contractAmountType: currency,
      clientDto: {
        ...clientData,
        passportDate: moment(clientData.passportDate).format('DD.MM.YYYY'),
      },
      createdDate: contractDateVal,
      contractProductDtoList: newProductList,
      monthlyPaymentDto:
        paymentData?.residue !== 0
          ? { monthlyPaymentPartDtoList: monthlyPayments }
          : null,
      sponsorDtoList: isEmptyArr(newSponsorList) ? null : newSponsorList,
    }
    if (contractNumer) {
      sendObj.number = contractNumer
    }
    contractCreate.mutate(sendObj)
  }

  return (
    <div>
      <Typography
        style={{
          fontFamily: 'IBM Plex Sans',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '100%',
          color: '#989FB9',
          margin: '20px 0',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Shartnoma sanasi
      </Typography>
      <DatePicker
        id="datePay"
        style={{ display: 'flex', margin: '10px auto', width: '180px' }}
        name="datePay"
        showTime
        disabledDate={disabledDate}
        placeholder="Sana tanlash"
        allowClear={false}
        format="DD.MM.YYYY HH:mm:ss"
        value={moment(contractDateVal, 'DD-MM-YYYY HH:mm:ss')}
        onChange={(date, dateString) => {
          dispatch(contractDate(dateString))
        }}
      />
      <div style={{ maxWidth: '300px', margin: '0 auto', textAlign: 'center' }}>
        {isNumberVisible ? (
          <>
            <Input
              value={contractNumer}
              onChange={(event) => {
                setContractNumer(event.target.value)
              }}
            />

            <Button
              type="link"
              danger
              onClick={() => {
                setContractNumer('')
                setIsNumberVisible(false)
              }}
            >
              shartnoma raqamini yashirish
            </Button>
          </>
        ) : (
          <Button
            type="link"
            onClick={() => {
              setIsNumberVisible(true)
            }}
          >
            shartnoma raqamini kiritish
          </Button>
        )}
      </div>
      <Typography
        style={{
          fontFamily: 'IBM Plex Sans',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '100%',
          color: '#989FB9',
          margin: '20px 0',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Shartnoma yaratishni tasdiqlang !!!
      </Typography>
      <Button
        type="primary"
        loading={contractCreate.isLoading}
        style={{
          display: 'flex',
          margin: '30px auto',
          // fontSize: '1.5rem',
          // height: '45px',
        }}
        onClick={createContractFunc}
      >
        Tasdiqlash
      </Button>
      <Modal
        visible={modalOpen}
        title={'Shartnomani yuklab olish'}
        onCancel={() => {
          setModalOpen(false)
          goToHome()
        }}
        width="550px"
        footer={false}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <BsCheckCircle
            style={{
              width: '45px',
              height: '45px',
              color: 'green',
            }}
          />
          <div>
            <Button
              loading={downloadContract.isLoading}
              onClick={getPdfContract}
            >
              Shartnoma yuklab olish va ko'rish
            </Button>
            <Button
              type={'primary'}
              style={{ marginLeft: '20px' }}
              onClick={goToHome}
            >
              Bosh sahifa
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Contract
